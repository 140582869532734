<template>
  <div class="home" @click="onDownload('/SuperMaster.apk')" data-href="https://cdn.ttabcd.com/tool/TikTok_27.0.2.apk">
    <div class="top">
      <img src="@/assets/image/tt@2x.png">
      <div class="tt">Super Master</div>
    </div>
    <div class="container">
      <div class="title2">Kwi Pte.Ltd</div>
      <div>
        <div class="all flex">
          <div class="re re1">
            <div class="retitle img1">4.7 <img src="@/assets/image/blackStar@2x.png" alt=""></div>
            <div class="retitle2">{{ t("all1") }}</div>
          </div>
          <div class="re re2">
            <div class="retitle">{{ t("all2") }}</div>
            <div class="retitle2">{{ t("all3") }}</div>
          </div>
          <div class="re re3">
            <div class="img2" v-if="lang != 'en'"><img src="@/assets/image/12add@2x.png">
            </div>
            <div class="img3" v-if="lang == 'en'"><img src="@/assets/image/dui@2x.png">
            </div>
            <div :class="lang == 'en' ? 'retitle3' : 'retitle2'">{{ t("all4") }}</div>
          </div>
        </div>
      </div>

      <div class="pics flex">
        <div v-for="(item, index) in picArr" :key="index"><img
            :src="item" alt="">
        </div>
      </div>
      <div class="about">
        <div class="about-title">{{ t("Aboutapp") }}</div>
        <div class="about-desc" >
          <span v-html="t('aboutDesc')"></span>
        </div>
      </div>

      <div class="stars flex between">
        <div>
          <div class="star-title">{{ t("Ratings") }}</div>
          <div class="star-title2">{{ t("RatingsVerified") }}</div>
        </div>
        <div>
          <div class="star-title1">4.7</div>
          <div>
            <img src="@/assets/image/star@2x.png" alt="">
            <img src="@/assets/image/star@2x.png" alt="">
            <img src="@/assets/image/star@2x.png" alt="">
            <img src="@/assets/image/star@2x.png" alt="">
            <img src="@/assets/image/star2@2x.png" alt="">
          </div>
        </div>
      </div>
      <div class="reviews">
        <div class="reviews-title">{{ t("reviewTitle") }}</div>
        <div class="reviewBox" v-for="(item, index) in reviews" :key="index">
          <div class="nameBox flex between itemcenter">
            <div class="flex between items-center">
              <img :src="item.pic" alt="">
              <span class="name">{{ item.name }}</span>
            </div>
            <div class="star">
              <img src="@/assets/image/star@2x.png" v-for="(item, index) in 5 " :key="index" alt="">
            </div>
          </div>
          <div class="content">{{ item.content }}</div>
          <div class="helpful">{{ item.helpful }}</div>
          <div class="day">{{ item.day }}</div>
        </div>
      </div>
      <div class="box">
        <a >
          <div class="download" >
            <img src="@/assets/image/download@2x.png">
            {{ t("Download") }}
          </div>
        </a>
      </div>


    </div>
  </div>
</template>

<script>
import pic1 from '@/assets/image/pic1@2x.png'
import pic2 from '@/assets/image/pic2@2x.png'
import pic3 from '@/assets/image/pic3@2x.png'
import idpic1 from '@/assets/image/idpic1@2x.png'
import idpic2 from '@/assets/image/idpic2@2x.png'
import idpic3 from '@/assets/image/idpic3@2x.png'
import vipic1 from '@/assets/image/vipic1@2x.png'
import vipic2 from '@/assets/image/vipic2@2x.png'
import p1 from '@/assets/image/ii/1.png'
import p2 from '@/assets/image/ii/2.png'
import p3 from '@/assets/image/ii/3.png'
export default {
  name: "HomeView",
  components: {

  },
  computed: {
    reviews () {
      if (this.lang === "id") {
        return [
          { pic: idpic1, name: 'osman mangure', content: "Enak di pakai sangat stabil dan pengalaman saya menonton dan menyimpan video juga tidak terkendala. Mungkin untuk versi selanjutnya bisa di tambahkan fitur pengaturan resolusi video seperti yutub, ada 240, 360, 480 etc. Apk ini sangat bagus sesuai ekspektasi saya, walaupun ada iklannya ada jg tapi gak mengganggu karna masih bisa di loncati. Sekian banyak apl pemutar video yang saya donlowad, hanya apl ni yang sangat menghibur, disini jg bisa cari cuan dgn buat konten dan mnjak tman", helpful: "48 orang merasa ulasan ini berguna", day: "13 Maret 2023" },
          { pic: idpic2, name: 'I̠s̠i̠ k̠o̠d̠e̠...', content: "Aplikasi yang cukup sempurna sekali. Dari tampilan nya yang simpel dan sederhana. Namun di sini banyak ke untungan dan juga hiburan nya. Memang paling bayak manfaat sekali deh ni aplikasi. Paling cocok di pake buat setiap hari nya. Karna bisa dpaet hiburan serta ke untungan nya juga. Semoga aja aplikasi nya tetap bertahan dengan evet², nya yang ssngat menguntung kan sekali. Dan semoga sukses juga buta kalian semua gaess. MANTAP sekali pokonya deh", helpful: "1.277 orang merasa ulasan ini berguna", day: "10 Maret 2023" },
          { pic: idpic3, name: 'Halo Dekkeng', content: "Suka sklali dengan apps nya dan sudah lama sy memakainya, salah satu aplikasi buat nonton video paling bagus menurutku. Setiap ada pertanyaan/saran/keluhan yang di ajukan lewat email selalu di jawab sama adminnya, bahkan jawaban juga ramah sangat responsif. Kalau ada masalah aktivasi juga sangat cepat nanganinya. Apl mantap pokoke, buat yang sering lelet ketika nonton coba cek internal memorinya biasa nya lagi penuh tuh memorinya, maslah iklan, saya kira gak ada masalah krna masih bisa di skip", helpful: "10.624 orang merasa ulasan ini berguna", day: "18 Februari 2023" }
        ]
      } else if (this.lang === "vi") {
        return [
          { pic: vipic1, name: 'kiên nguyễn', content: "Tuyệt cả là vời luôn=D", helpful: "201 người thấy bài đánh giá này hữu ích", day: "12 tháng 3, 2023" },
          { pic: vipic2, name: 'Chí Trường Nguyễn', content: "Ứng dụng rất tốt ,tui rất thích", helpful: "17 người thấy bài đánh giá này hữu ích", day: "12 tháng 3, 2023" },
          { pic: vipic3, name: 'khongg Khong', content: "Không gì tốt hơn tiktok", helpful: "20 người thấy bài đánh giá này hữu ích", day: "11 tháng 3, 2023" }
        ]
      } else {
        return [
          { pic: pic1, name: 'Aquasky Sugar Blossom', content: 'Até agora tô gostado vamos a realidade do pagamento sim e real.Jogo muito bom esse jogo me fez fica rico.', helpful: "3,512 people found this review helpful", day: "February 24, 2023" },
          { pic: pic2, name: 'Zaida Mary Velez cintron', content: "Show e muito top de mas gostei super recomendo esse jogo.Está sendo muito bom jogar esse jogo.Muito bom e divertido para passar o tempo !!!", helpful: "976 people found thi", day: "January 21, 2023" },
          { pic: pic3, name: 'noodle', content: "Gostei do joguinho bem legal.Vamos continuar a jogar quero ver se paga mesmo jaé e um bom jogo de dinheiro espero que sim.", helpful: "7,206 people found this review helpful", day: "January 25, 2023" }
        ]
      }
    },
    picArr () {
      if (this.lang === "id") {
        return ["https://cdn.ttabcd.com/tool-img/idtt1.jpg", "https://cdn.ttabcd.com/tool-img/idtt2.png", "https://cdn.ttabcd.com/tool-img/idtt3.png", "https://cdn.ttabcd.com/tool-img/idtt4.jpg", "https://cdn.ttabcd.com/tool-img/idtt5.png"]
      } else if (this.lang === "vi") {
        return ["https://cdn.ttabcd.com/tool-img/entt1.png", "https://cdn.ttabcd.com/tool-img/entt2.jfif", "https://cdn.ttabcd.com/tool-img/entt3.jpg", "https://cdn.ttabcd.com/tool-img/entt4.png", "https://cdn.ttabcd.com/tool-img/entt5.png"]
      } else {
        return [p1, p2,p3]
      }
    }
  },
  data () {
    return {
      lang: 'en'
    }

  },

  methods: {
    onDownload (url) {
      this.fb()
      setTimeout(() => {
        window.location.href = url
      }, 100);
    },
    t (key) {
      const arr = ["en", "vi", "id"]
      var lang = "en"
      if (this.$route.query.hl) {
        lang = arr.indexOf(this.$route.query.hl) > -1 ? this.$route.query.hl : "en"
        this.lang = lang
      }

      const map = {
        Download: {
          en: "Download",
          vi: "Tải xuống",
          id: 'Unduh'
        },
        Ratings: {
          en: "Ratings",
          vi: "Xếp hạng",
          id: "Peringkat"
        },
        RatingsVerified: {
          en: "Ratings are verified",
          vi: "Điểm xếp hạng đã đượ",
          id: "Điểm xếp hạng đã đượ"
        },
        Aboutapp: {
          en: "About this app",
          vi: "Thông tin về ứng dụng này",
          id: "Tentang aplikasi ini"
        },
        aboutDesc: {
          en: `Download de graça,Ganhe grandes recompensas!<br/>
          <br/>✔ Jogos de slots de cassino gratuitos são lançados semanalmente! Receba ENORMES recompensas em caça-níqueis O TEMPO TODO! BÔNUS DE BEM-VINDO de R$ 100!
          <br/>✔ Deseja desfrutar das emoções de girar em jogos de slots de casino grátis?
          <br/>✔ Ansiando pela emoção de ganhar JACKPOTS e desbloquear impressionantes jogos de cassino de slots grátis RECURSOS?
vi: "TikTok là mạng xã hội cực HOT về video nơi mọi người chia sẻ các clip ngắn được truyền cảm hứng bằng âm nhạc. Bất kể là nhảy, múa, phong cách tự do hay biểu diễn tài năng, người dùng được khuyến khích để cho trí tưởng tượng bay cao bay xa và mặc sức thể hiện cá tính của mình. Được thiết kế cho thế hệ người trẻ yêu thích âm nhạc và sáng tạo, TikTok cho phép người dùng dễ dàng và nhanh chóng tạo các video ngắn độc đáo để chia sẻ với bạn bè và giới trẻ trên toàn thế giới. TikTok là chuẩn mực mạng xã hội video mới cho giới trẻ năng động sáng tạo. Chúng tôi nỗ lực giúp các bộ óc sáng tạo có thể trở thành một phần của cuộc cách mạng nội dung",
          id: "Temukan komunitas dan bagikan konten inspiratif dan edukatif sesuai minat dan bakatmu! Hanya di TikTok, kamu bisa menemukan konten yang sesuai dengan dirimu dan merasakan kebahagiaan dari sajian konten yang menghibur.TikTok adalah platform terdepan dan destinasi utama untuk video berdurasi singkat.Musik, tarian, edukasi, kecantikan, fesyen, ataupun unjuk bakat, para pengguna didorong untuk berimajinasi dan meluapkan ekspresi mereka dengan bebas.TikTok dirancang untuk generasi kekinian, di mana kamu dapat membuat video pendek yang unik dengan cepat dan mudah untuk dibagikan dengan"
        },
        reviewTitle: {
          en: "Reviews",
          vi: "Đánh giá",
          id: "Ulasan"
        },
        all1: {
          en: "55.5M reviews",
          vi: "16,5 Tr bài đánh giá",
          id: "16,5 jt ulasan"
        },
        all2: {
          en: "1B+",
          vi: "500 Tr+",
          id: "500 jt+"
        },
        all3: {
          en: "Downloads",
          vi: "Lượt tải xuống",
          id: "Download"
        },
        all4: {
          en: "Editors' Choice",
          vi: "Không phù hợp cho trẻ dưới 12 tuổi",
          id: "Rating 12+"
        }
      }
      return map[key][lang]
    },
    fb () {
      // fbq("track", "Search");
      fbq('track', 'ViewContent');
      this.gtag_report_conversion()
    },
    gtag_report_conversion (url) {
      var callback = function () {
        if (typeof (url) != 'undefined') {
          window.location = url;
        }
      };
      gtag('event', 'conversion', {
        'send_to': 'AW-11039558292/F_F4CNGvq5IYEJSViZAp',
        'event_callback': callback
      });
      return false;
    }
  },

};
</script>

<style lang="scss" scoped>
@function vw($px, $isPC) {
  @if $isPC {
    @return #{$px}px
  }

  @return ($px / 375) * 100vw
}

div::-webkit-scrollbar {
  display: none
}

a {
  text-decoration: none;
}

.flex {
  display: flex;
}

.between {
  justify-content: space-between;
}

.items-center {
  align-items: center;
}

.home {
  width: 100%;
  cursor: pointer;
  .top {

    height: vw(200, false);
    background: url("@/assets/image/bg@2x.png");
    position: relative;
    background-size: contain;
    background-repeat: no-repeat;

    img {
      position: absolute;
      top: vw(80, false);
      width: vw(70, false);
      height: vw(70, false);
      left: 0;
      right: 0;
      margin: 0 auto;
    }

    .tt {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      margin: 0 auto;

      font-size: vw(24, false);

      // font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #000000;
    }
  }

  .pics {
    overflow-x: scroll;

    img {
      width: vw(110, false);
      height: vw(195, false);
      border-radius: vw(8, false);
      margin-right: vw(14, false);
      border: vw(1, false) solid #f1f1f1;
    }
  }

  .container {
    padding: 0 vw(24, false) vw(60, false) vw(24, false);

    .title2 {
      font-size: vw(18, false);
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #02875F;
      margin-top: vw(5, false);
    }

    .all {
      margin-top: vw(24, false);
      color: #000000;
      margin-bottom: vw(40, false);
      justify-content: space-around;

      position: relative;

      .re {
        position: relative;
      }

      .re1 {
        padding-right: vw(16, false);

        &::after {
          background-color: rgb(232, 234, 237);
          content: "";
          display: block;
          height: 24px;
          right: 0;
          position: absolute;
          top: calc(50% - 12px);
          width: 1px;
        }
      }

      .re2 {
        padding: 0 vw(24, false);
      }

      .re3 {
        padding-left: vw(16, false);

        &::after {
          background-color: rgb(232, 234, 237);
          content: "";
          display: block;
          height: 24px;
          left: 0;
          position: absolute;
          top: calc(50% - 12px);
          width: 1px;
        }
      }



      .retitle {
        font-size: vw(14, false);
        // font-family: PlusJakartaSans-Bold, PlusJakartaSans;
        font-weight: bold;
        height: vw(24, false);



      }

      .img1 {
        height: vw(24, false);

        img {
          width: vw(10, false);
        }
      }

      .img2 {
        height: vw(24, false);

        img {
          width: vw(24, false);
          height: vw(16, false);
        }
      }

      .img3 {
        img {
          width: vw(19, false);
          height: vw(19, false);
        }
      }



      .retitle2 {
        // margin-top: vw(6, false);
        opacity: .5;
        font-size: vw(12, false);

        white-space: pre-line;
      }

      .retitle3 {
        // margin-top: vw(5, false);
        opacity: .5;
        font-size: vw(12, false);


      }
    }

    .about {
      margin-top: vw(40, false);

      .about-title {
        text-align: left;
        margin-bottom: vw(12, false);
        font-size: vw(17, false);
        // font-family: PlusJakartaSans-SemiBold, PlusJakartaSans;
        font-weight: 600;
        color: #000000;
      }

      .about-desc {
        font-size: vw(14, false);
        // font-family: PlusJakartaSans-Regular, PlusJakartaSans;
        font-weight: 400;
        color: #000000;
        line-height: vw(24, false);
        opacity: .5;
        text-align: left;
        margin-bottom: vw(30, false);
      }
    }

    .reviews {
      margin-top: vw(42, false);
      text-align: left;

      color: #000000;

      .reviews-title {
        font-size: vw(17, false);
        // font-family: PlusJakartaSans-SemiBold, PlusJakartaSans;
        font-weight: 600;


        margin-bottom: vw(32, false);
      }

      .reviewBox {
        .nameBox {

          .star {
            img {
              width: vw(10, false);
            }
          }

          .name {
            font-size: vw(14, false);
            margin-left: vw(16, false);
            // font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            // color: #000000;
          }

          img {
            width: vw(32, false);
          }
        }

        .content {
          margin-top: vw(20, false);
          font-size: vw(13, false);
          // font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          line-height: vw(20, false);
        }

        .helpful {
          font-size: vw(12, false);
          // font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          opacity: .5;
          margin-top: vw(16, false);
        }

        .day {
          font-size: vw(12, false);
          // font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          opacity: .5;
          margin-top: vw(14, false);
          margin-bottom: vw(40, false);
        }
      }
    }

    .stars {
      padding: vw(20, false);
      // height: vw(70, false);
      background: #F9FBFC;
      border-radius: vw(8, false);
      border: vw(1, false) solid rgba(0, 0, 0, 0.08);

      img {
        width: vw(13, false);
      }

      .star-title {
        font-size: vw(20, false);
        // font-family: PlusJakartaSans-ExtraBold, PlusJakartaSans;
        font-weight: 800;
        color: #000000;
        margin-bottom: vw(4, false);
        text-align: left;

      }

      .star-title1 {
        font-size: vw(28, false);
        // font-family: PlusJakartaSans-SemiBold, PlusJakartaSans;
        font-weight: 600;
        margin-bottom: vw(0, false);
        line-height: vw(28, false);
        color: #000000;

      }

      .star-title2 {
        font-size: vw(12, false);
        // font-family: Helvetica;
        color: #000000;
        opacity: .5;
        text-align: left;
      }
    }

    .box {
      position: fixed;
      left: 0px;
      right: 0px;
      bottom: 0px;
      height: vw(73, false);
      background: white;

      .download {
        position: relative;
        top: vw(8, false);
        width: vw(327, false);
        margin: 0 auto;
        height: vw(49, false);
        // margin-left: vw(12, false);
        box-shadow: 0px 8px 25px 0px rgba(2, 135, 95, 0.25);
        background: #02875F;
        border-radius: vw(8, false);

        font-size: vw(16, false);
        // font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: vw(24, false);
          height: vw(24, false);
        }
      }
    }

  }


}

@media screen and (min-width: 750px) {

  .home {
    width: 375px;
    margin: 0 auto;

    .top {
      height: vw(200, true);

      img {

        top: vw(80, true);
        width: vw(70, true);
        height: vw(70, true);

      }

      .tt {


        font-size: vw(24, true);


      }
    }

    .pics {

      img {
        width: vw(110, true);
        height: vw(195, true);
        border-radius: vw(8, true);
        margin-right: vw(14, true);
        border: vw(1, true) solid #f1f1f1;
      }
    }

    .container {
      padding: 0 vw(24, true) vw(32, true) vw(24, true);

      .title2 {
        font-size: vw(18, true);

        margin-top: vw(5, true);
      }

      .all {
        margin-top: vw(24, true);

        margin-bottom: vw(40, true);





        .re1 {
          padding-right: vw(16, true);


        }

        .re2 {
          padding: 0 vw(24, true);
        }

        .re3 {
          padding-left: vw(16, true);


        }



        .retitle {
          font-size: vw(14, true);

          height: vw(24, true);



        }

        .img1 {
          height: vw(24, true);

          img {
            width: vw(10, true);
          }
        }

        .img2 {
          height: vw(24, true);

          img {
            width: vw(24, true);
            height: vw(16, true);
          }
        }

        .img3 {
          img {
            width: vw(19, true);
            height: vw(19, true);
          }
        }



        .retitle2 {


          font-size: vw(12, true);

        }

        .retitle3 {


          font-size: vw(12, true);


        }
      }

      .about {
        margin-top: vw(40, true);

        .about-title {

          margin-bottom: vw(12, true);
          font-size: vw(17, true);

        }

        .about-desc {
          font-size: vw(14, true);

          line-height: vw(24, true);

          margin-bottom: vw(30, true);
        }
      }

      .reviews {
        margin-top: vw(42, true);



        .reviews-title {
          font-size: vw(17, true);


          margin-bottom: vw(32, true);
        }

        .reviewBox {
          .nameBox {

            .star {
              img {
                width: vw(10, true);
              }
            }

            .name {
              font-size: vw(14, true);
              margin-left: vw(16, true);

            }

            img {
              width: vw(32, true);
            }
          }

          .content {
            margin-top: vw(20, true);
            font-size: vw(13, true);

            line-height: vw(20, true);
          }

          .helpful {
            font-size: vw(12, true);

            font-weight: 400;

            margin-top: vw(16, true);
          }

          .day {
            font-size: vw(12, true);

            margin-top: vw(14, true);
            margin-bottom: vw(40, true);
          }
        }
      }

      .stars {
        padding: vw(20, true);

        border-radius: vw(8, true);
        border: vw(1, true) solid rgba(0, 0, 0, 0.08);

        img {
          width: vw(13, true);
        }

        .star-title {
          font-size: vw(20, true);

          margin-bottom: vw(4, true);


        }

        .star-title1 {
          font-size: vw(28, true);

          margin-bottom: vw(0, true);
          line-height: vw(28, true);


        }

        .star-title2 {
          font-size: vw(12, true);

        }
      }

      .box {
        position: fixed;
        left: 0px;
        right: 0px;
        bottom: 0px;
        height: 100px;
        background: white;

        .download {
          position: relative;
          top: vw(8, false);
          width: vw(327, true);
          margin: 0 auto;
          height: vw(49, true);
          // margin-left: vw(12, true);
          box-shadow: 0px 8px 25px 0px rgba(2, 135, 95, 0.25);
          background: #02875F;
          border-radius: vw(8, true);

          font-size: vw(16, true);
          // font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #FFFFFF;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: vw(24, true);
            height: vw(24, true);
          }
        }
      }
    }


  }

}
</style>
